<!-- 公共头部菜单 -->
<template>
  <div class="menuWrap">
    <div :class="['menuT mb20', { 'hiddenRainbow': $route.meta.hiddenRainbow }]">
      <div :class="['userWrap', { 'hiddenRainbow': $route.meta.hiddenRainbow }]"
        :style="{ backgroundImage: 'url(' + bg1 + ')' }">
        <template v-if="isShowUser">
          <!--注册，对应router/index.js中的meta.regist-->
          <div class="registerBtn" v-show="$route.meta.regist">
            <img :src="registerBtn" alt="快速注册" @click="goRegister">
          </div>
          <!--登录,对应router/index.js中的meta.login-->
          <div class="userBar" v-show="$route.meta.login">
            <div class="userInfo">
              <template v-if="isLogin == 1">
                <div class="title">账号信息</div>
                <div class="itemGroup">
                  <div class="item"><span class="tit">账<b></b>号：</span><span class="txt">{{ userInfo.username }}</span>
                  </div>
                  <div class="item"><span class="tit">昵<b></b>称：</span><span class="txt">{{ userInfo.usernick }}</span>
                  </div>
                  <div class="item"><span class="tit">等<b></b>级：</span><span class="txt">{{ userInfo.vipInfo.viplevel
                      }}</span></div>
                  <div class="item"><span class="tit">白金币：</span><span class="txt">{{ userInfo.wallteInfo.chargeMoney
                      }}</span><img class="btn" :src="rechargeImg" alt="充值" @click="goCharge"></div>
                  <!--<div class="item"><span class="tit">泡<b></b>点：</span><span class="txt">{{userInfo.gamePoints}}</span><img class="btn" :src="exchangeImg" alt="兑换" @click="goAccount"></div>-->
                  <div class="item"><span class="tit">积<b></b>分：</span><span class="txt">{{ userInfo.vipInfo.points
                      }}</span><img class="btn" :src="exchangeImg" alt="兑换" @click="goShop"></div>
                </div>
                <div class="userIcon">
                  <img v-for="item in userInfoIcon" :src="item.imgUrl" @click="goOther(item)" alt="">
                </div>
              </template>
              <template v-if="isLogin == 0">
                <div class="title">游戏账号登录</div>
                <div class="indexLogin">
                  <loginWX :type="1" @loginSuccess="loginSuccess" @forgetPsw="forgetPsw"></loginWX>
                </div>
              </template>
            </div>
          </div>
        </template>
        <!--下载,对应router/index.js中的meta.download-->
        <div class="fastDownload txtC" v-show="$route.meta.download">
          <img :src="fastDownloadImg" alt="极速下载游戏" @click="goDownLoad">
        </div>
        <!--enter,对应router/index.js中的meta.enter-->
        <div class="enter txtC" v-show="$route.meta.enter">
          <img class="enterBtn" v-for="item in enterIcon" :src="item.imgUrl" @click="goOther(item)" alt="">
        </div>
        <!--记录,对应router/index.js中的meta.record,但账户中心只有登录才展示此部分-->
        <div class="enter txtC userBar"
          v-show="$route.meta.record && ($route.name == 'Account' && !isLogin ? false : true)">
          <img class="enterBtn" v-for="item in recordIcon" :src="item.imgUrl" :alt="item.alt" width="85" height="33"
            @click="goOther(item)" alt="">
        </div>
        <!--广告牌,对应router/index.js中的meta.ad-->
        <div class="gameTip txtC" v-show="$route.meta.ad">
          <img :src="gameTip" alt="">
        </div>
      </div>
    </div>
    <!--标签,对应router/index.js中的meta.label-->
    <div class="menuC bgWhite mb20" v-show="$route.meta.label">
      <div class="codeWrap pd15">
        <div class="tit txtC">
          <span v-for="(item, index) in codeTit" :class="{ active: codeActive == index }" @click="changeTag(index)">{{
            item
          }}</span>
        </div>
        <div class="cons">
          <img :src="codeIcon[codeActive]" alt="">
        </div>
      </div>
      <div class="callQQ txtC">
        <a target="_blank" href="tencent://message/?uin=3003533756&Site=wx.qtthtech.com&Menu=yes">
          <img border="0" :src="qqIcon" alt="点击这里给我发消息" title="点击这里给我发消息" />
        </a>
      </div>
      <div class="callEmail txtC">
        <img :src="emailIcon" alt="">
        <div class="email">service@qtthtech.com</div>
      </div>
    </div>
    <!--配置,对应router/index.js中的meta.config-->
    <div class="menuB bgWhite" v-show="$route.meta.config">
      <div class="config">
        <div class="tit">
          <span v-for="(item, index) in configTit" :class="{ active: configActive == index }"
            @click="changeConfig(index)">{{ item }}</span>
        </div>
        <div class="cons">
          <div class="item">
            <span>处理器：{{ configCons[configActive].name }}</span>
            <span>内存：{{ configCons[configActive].storage }}</span>
            <span>图形：{{ configCons[configActive].graph }}</span>
            <span>储存空间：{{ configCons[configActive].space }}</span>
            <span>显卡：{{ configCons[configActive].card }}</span>
          </div>
        </div>
      </div>
    </div>
    <!--排行榜,对应router/index.js中的meta.rank,但账户中心时，只有游戏充值和积分记录有-->
    <rank-table v-if="$route.meta.rank"></rank-table>
    <!--舞侠红榜对应router/index.js中的meta.dance-->
    <dance-table v-if="$route.meta.dance"></dance-table>
    <!--BGM红榜对应router/index.js中的meta.bgm-->
    <!--<bgm-table v-if="$route.meta.bgm" class="mt20"></bgm-table>-->
    <!--公告排行榜对应router/index.js中的meta.notice-->
    <notice-table v-if="$route.meta.notice" class="mt20"></notice-table>

    <pubMask v-if="isShowPask" :isShowPask="isShowPask" :propShowSwitch="showSwitch" :title="diaTitle"></pubMask>
  </div>
</template>

<script>
import loginWX from '@/views/login/login'
import rankTable from './rankTable.vue'
import danceTable from './danceTable.vue'
import bgmTable from './bgmTable.vue'
import noticeTable from './noticeTable.vue'
import { publicUrl } from '@/common/publicUrl.js'
import pubMask from '@/components/pubMask'
import eventBus from '@/common/bus.js'

import Cookies from 'js-cookie'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    loginWX,
    rankTable,
    danceTable,
    bgmTable,
    noticeTable,
    pubMask
  },
  data() {
    return {
      isShowPask: false,
      showSwitch: 0,
      diaTitle: '',
      isShowUser: true,
      userInfo: {
        verifyInfo: {},
        vipInfo: {},
        wallteInfo: {},
      },            // 用户信息
      isLogin: 0,// 用户是否登录，1：登录 0：未登录
      bg1: require('@/assets/imgs/menu/navTopBg.png'),
      registerBtn: require('@/assets/imgs/menu/registerBtn.png'),    // 注册按钮
      rechargeImg: require('@/assets/imgs/menu/recharge.png'),       // 充值按钮
      exchangeImg: require('@/assets/imgs/menu/exchange.png'),       // 兑换按钮
      userInfoIcon: [                                                // 账户中心等按钮
        { type: 'Warehouse', imgUrl: require('@/assets/imgs/menu/userInfoIcon1.png') },                // 个人仓库（商城）
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/userInfoIcon2.png'), queryType: 0 },       // 记录
        { type: 'Account', imgUrl: require('@/assets/imgs/menu/userInfoIcon3.png') },      // 个人中心
        { type: 'tuichu', imgUrl: require('@/assets/imgs/menu/userInfoIcon4.png') },                   // 退出登录
      ],
      fastDownloadImg: require('@/assets/imgs/menu/fastDownload.png'),
      enterIcon: [                                                   // 进入各个页面的入口
        { type: 'Recharge', imgUrl: require('@/assets/imgs/menu/enterIcon1.png') },                  // 游戏充值
        { type: 'Account', imgUrl: require('@/assets/imgs/menu/enterIcon2.png'), queryType: 0 },       // 游戏划分
        { type: 'Notice', imgUrl: require('@/assets/imgs/menu/enterIcon3.png') },                    // 游戏公告
        { type: 'shangcheng', imgUrl: require('@/assets/imgs/menu/enterIcon4.png') },                // 游戏商城
        { type: 'Account', imgUrl: require('@/assets/imgs/menu/enterIcon5.png'), queryType: 2 },       // 泡点系统
        { type: 'SignIn', imgUrl: require('@/assets/imgs/menu/enterIcon6.png') },                 // 每日签到
        { type: 'Account', imgUrl: require('@/assets/imgs/menu/enterIcon7.png'), queryType: 1 },       // 个人中心 - vip
        { type: 'Account', imgUrl: require('@/assets/imgs/menu/enterIcon8.png'), queryType: 0 },       // 个人中心 - 账户中心
      ],
      recordIcon: [
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/recordIcon1.png'), alt: '充值记录', queryType: 1 },
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/recordIcon2.png'), alt: '划分记录', queryType: 3 },
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/recordIcon3.png'), alt: '消费记录', queryType: 2 },
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/recordIcon4.png'), alt: '泡点记录', queryType: 4 },
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/recordIcon5.png'), alt: '积分记录', queryType: 5 },
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/recordIcon6.png'), alt: '公告记录', queryType: 0 },
        { type: 'Record', imgUrl: require('@/assets/imgs/menu/recordIcon7.png'), alt: '安全日志', queryType: 6 },
      ],
      gameTip: require('@/assets/imgs/menu/gameTip.png'),            // 游戏提示

      codeActive: 0,
      codeTit: ['微信群', 'QQ群', '抖音'],
      codeIcon: [                                                    // 二维码
        require('@/assets/imgs/menu/wx.png'),
        require('@/assets/imgs/menu/qq.png'),
        require('@/assets/imgs/menu/dy.png'),
      ],
      qqIcon: require('@/assets/imgs/menu/qqIcon.png'),             // 点击呼叫qq
      emailIcon: require('@/assets/imgs/menu/emailIcon.png'),       // email

      configActive: 0,
      configTit: ['最佳配置', '最低配置'],
      configCons: [
        { name: 'i5及以上处理器', storage: '16.00GB', graph: 'DirectX版本：9.0c', space: '100G以上存储', card: 'GTX 780M以上' },
        { name: '双核及以上', storage: '8.00GB', graph: 'DirectX版本：9.0c', space: '100G以上存储', card: 'GT 430以上' }
      ],
    }
  },
  created() {
    let _this = this
    window.addEventListener('setItem', () => {
      _this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    });
    eventBus.$on('closeMask', this.closeMask)
    eventBus.$on('loginSuccess', this.loginSuccess)

    if (Cookies.get('wx_token')) {
      this.getUserInfo()
    } else {
      localStorage.removeItem('userInfo')
    }

  },
  mounted() {
    if (Cookies.get('wx_userId')) {
      this.isLogin = 1
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    } else {
      this.isLogin = 0
    }
  },
  methods: {
    goShop() {
      window.open(publicUrl.shopHomeUrl, '_blank')
    },
    closeMask() {
      this.isShowPask = false;
      this.showSwitch = 0
    },
    forgetPsw() {
      this.isShowPask = true;
      this.showSwitch = 1
      this.diaTitle = '忘记密码'
    },         // 忘记密码
    loginSuccess() {           // 登录成功
      
      this.getUserInfo()
      this.isLogin = 1
    },
    getUserInfo() {// 获取用户信息
      axios({
        method: 'post',
        url: '/user/getMemberInfo',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          platForm: 'web',
          userId: Cookies.get('wx_userId')
        },
      }).then(res => {
        if (res.data.state == 200) {
          if (res.data && res.data.data) {
            this.userInfo = res.data.data
            this.resetSetItem('userInfo', JSON.stringify(res.data.data))
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    logOut() {        // 退出登录
      axios({
        method: 'post',
        url: '/login/logout?source=web&userId=' + Cookies.get('wx_userId'),
        headers: {
          token: Cookies.get('wx_token'),
        },
      }).then(res => {
        if (res.data.state == 200) {
          this.$message.success('退出成功')
          this.isLogin = 0

          Cookies.set('wx_userId', '', { domain: '.qtthtech.com' });
          Cookies.set('wx_token', '', { domain: '.qtthtech.com' });
          Cookies.remove('wx_token')
          localStorage.removeItem('userInfo')

          // localStorage.clear() //清除所有localStorage数据
          // console.log('清除所有localStorage数据');
          window.location.reload()
          if (this.$route.name != '/') {
            this.$router.push({
              path: '/',
            })
          } else {
            this.$router.go(0)
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    /*************************** 跳转相关 */
    goDownLoad() {       // 跳转到下载中心
      const tiao = localStorage.getItem('17173') ? localStorage.getItem('17173') : 0;
      const tiao2 = localStorage.getItem('9i173') ? localStorage.getItem('9i173') : 0;

      if ((tiao !== 0 || tiao2 !== 0)) {
        if (tiao !== 0) {

          window.open('https://www.17173.com', '_blank')

        } else if (tiao2 !== 0) {
          window.open('http://wx.9i173.com/', '_blank')
        }
        return
      } else {
        this.$router.push('/downLoad')
      }

    },
    goOther(item) {
      console.log(item);
      let routeData = ''
      if (item.type == 'tuichu') {
        this.logOut()
        return false
      } else if (item.type == 'shangcheng') {
        window.open(publicUrl.shopHomeUrl, '_blank');         // 商城
      } else if (item.type == 'Warehouse') {
        window.open(publicUrl.shopWarehouseUrl, '_blank');    // 个人仓库
        return false
      } 
      // else if (item.type == 'SignIn') {     // 签到
      //   this.$message.error('暂时还没有数据哦！')
      //   return false
      // } 
      else {
        if (item.type == 'Account') {
          if (item.queryType) {
            this.$router.push({
              name: item.type,
              query: {
                type: item.queryType,
              }
            })
          } else {
            this.$router.push({
              name: item.type,
            })
          }
        } else {
          if (item.queryType || item.queryType == 0) {
            if (item.type == this.$route.name) {
              eventBus.$emit(item.type, item.queryType)
            } else {
              this.$router.push({
                name: item.type,
                query: {
                  type: item.queryType,
                }
              })
            }
          } else {
            if (item.type == this.$route.name) {
              return false
            } else {
              this.$router.push({
                name: item.type,
              })
            }
          }
        }
      }
    },
    goAccount() {       // 跳转到个人中心 - 泡点系统
      this.$router.push({
        path: '/account',
        query: {
          type: 2,
        }
      })
    },
    goCharge() {
      this.$router.push({
        path: '/recharge',
      })
    },
    goRegister() {       // 去注册页面
      const tiao = localStorage.getItem('17173') ? localStorage.getItem('17173') : 0;
      const tiao2 = localStorage.getItem('9i173') ? localStorage.getItem('9i173') : 0;

      if ((tiao !== 0 || tiao2 !== 0)) {
        if (tiao !== 0) {
          window.open('https://passport.17173.com/register', '_blank')
        } else if (tiao2 !== 0) {
          window.open('http://www.9i173.com/reg?p=ea574708ce10f0745a87dd86500ae83a', '_blank')
        }

        return
      } else {
        this.$router.push('/register')
      }


    },
    goNews() {          // 跳转到新闻页面
      this.$router.push('/news')
    },
    goRecruit() {       // 跳转到招募页面
      this.$router.push({
        path: '/recruit',
      })
    },
    goDaily() {       // 跳转到日常页面
      this.$router.push({
        path: '/daily',
      })
    },
    goCeremony() {       // 跳转到礼遇页面
      this.$router.push({
        path: '/ceremony',
      })
    },
    goIntegralShop() {       // 跳转到积分商城
      window.open(publicUrl.shopIntegralUrl, '_blank');    // 商城
    },
    goSongChart() {       // 跳转到歌曲打榜
      this.$message.error('暂时还没有数据哦！')
      return false
      /*this.$router.push({
        path:'/songChart',
      })*/
    },
    goNotice() {       // 跳转到公告页面
      this.$router.push({
        path: '/notice',
      })
    },
    goBlindBox() {       // 跳转商城盲盒页面
      window.open(publicUrl.shopBlindBoxUrl, '_blank'); // 商城
    },
    changeTag(index) {
      this.codeActive = index
    },
    changeConfig(index) {       // 配置
      this.configActive = index
    },
  },
  watch: {
    '$route'(to, from) {
      if (Cookies.get('wx_token')) {
        this.isLogin = 1
        this.getUserInfo()
      } else {
        this.isLogin = 0
      }
    }
  }
}
</script>
<style lang="less">
.menuWrap {
  .indexLogin {
    .el-form-item {
      margin-bottom: 18px;
      height: 36px;
    }

    .el-form-item__content {
      height: 36px;
      line-height: 36px;
    }

    .el-input {
      border: 1px dashed #ffffff;
      height: 36px;
      line-height: 36px;
      overflow: hidden;
      border-radius: 4px;
      box-sizing: border-box;
    }

    .el-input__inner {
      padding-left: 50px;
      height: 34px;
      line-height: 34px;
      border-radius: 0;
      border: none;
      background: none;
      color: #ffffff;

      &::placeholder {
        color: #ececec;
      }
    }

    .el-input__prefix {
      left: 0;
      display: flex;
      align-items: center;
      width: 40px;
      height: 34px;
      line-height: 34px;
      background: #ffffff;
      justify-content: center;

      i {
        height: 27px;
      }
    }

    .submitWrap {
      margin-bottom: 7px;

      .btn {
        padding: 0;
        width: 100%;
        height: 36px;
        line-height: 36px;
        background: #FF485E;
        border: none;

        span {
          font-size: 16px;
        }
      }
    }

    .forgetPsw {
      text-align: center;

      span {
        color: #ececec;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>

<style scoped lang="less">
.menuWrap {
  color: #ffffff;

  .menuT {
    border-top-left-radius: 114px;
    border-top-right-radius: 114px;
    background: linear-gradient(to bottom, #9730FF, #9930FD);

    .userWrap {
      padding-top: 122px;
      padding-bottom: 10px;
      background-repeat: no-repeat;

      .registerBtn {
        text-align: center;
        cursor: pointer;
      }

      .userBar {
        margin: 0 10px 16px;
        padding: 20px 0;
        border-top: 1px dashed #ffffff;
        border-bottom: 1px dashed #ffffff;

        .userInfo {
          padding: 10px;
          border: 1px solid #ffffff;
          background: rgba(255, 255, 255, 0.2);

          .title {
            margin-bottom: 5px;
            font-size: 16px;
          }

          .itemGroup {
            .item {
              position: relative;
              z-index: 0;
              display: flex;
              align-items: center;
              padding: 5px 0;
              height: 28px;
              box-sizing: border-box;

              span {
                font-size: 13px;
              }

              .tit {
                width: 56px;
                min-width: 56px;

                b {
                  padding: 0 7px;
                }
              }

              .txt {
                min-width: 100px;
                width: 130px;
                padding: 2px 0;
                border-bottom: 1px dashed #ffffff;
              }

              .btn {
                position: absolute;
                right: -10px;
                top: -1px;
              }

              img {
                cursor: pointer;
              }
            }
          }
        }

        .userIcon {
          padding-top: 5px;
          text-align: center;

          img {
            padding: 2px;
            cursor: pointer;
          }
        }
      }

      .fastDownload {
        margin-bottom: 13px;

        img {
          cursor: pointer;
        }
      }

      .enter {
        margin-bottom: 10px;

        .enterBtn {
          margin: 0 4px;
          cursor: pointer;
        }
      }

      .gameTip {
        padding-left: 10px;
      }
    }
  }

  .menuC {
    padding-bottom: 20px;

    .codeWrap {
      .tit {
        margin-bottom: 18px;

        span {
          margin: 0 11px;
          padding: 3px;
          color: #333;
          border-bottom: 3px solid #ffffff;
          cursor: pointer;
        }

        .active {
          color: #9500FF;
          border-bottom: 3px solid #9500FF;
          cursor: default;
        }
      }

      .cons {
        margin-bottom: 18px;

        img {
          width: 100%;
        }
      }
    }

    .callEmail {
      color: #5F9FFF;
    }
  }

  .menuB {
    color: #333333;

    .config {
      padding: 20px 30px;

      .tit {
        span {
          display: inline-block;
          width: 50%;
          height: 24px;
          line-height: 24px;
          text-align: center;
          background: #E8E8E8;
          cursor: pointer;
        }

        .active {
          color: #ffffff;
          background: #5457AF;
          cursor: default;
        }
      }

      .cons {
        padding: 10px 0 0 0;

        .item {
          span {
            display: block;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
</style>
